/** TODO: 
This accordion uses jQuery specific animations. such as `slideUp` and `slideDown`

Similar to slick.js, any jQuery specific JavaScript will not be converted in Refactor Phase 1
**/

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }

  static targets = ['item', 'actions', 'content']

  initialize() {
    // this.check();
    // this.events();
  }

  check() {
  }

  events() {
  }

  removeEvents() {
  }

  toggle() {
  }

  open() {
  }

  close(){
  }
}
